import { IQuizItem } from '../../types/quiz';
import { Button } from '../buttons/Button';
import { OptionButtons } from '../parts/OptionButtons';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionLottie } from '../parts/QuestionLottie';
import { QuestionText } from '../parts/QuestionText';
import { useEffect, useState } from 'react';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string | string[], title: string | string[]) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const MultipleQuestion = ({ item, onSubmit, lastQuestion, answers }: Props) => {
  const [valid, setValid] = useState(false);
  const [values, setValues] = useState<Record<string, boolean>>({});

  const submit = () => {
    const selectedValues = Object.keys(values).filter((key) => values[key]);
    //I need to get the titles of the selected values but from selectedValues
    const selectedTitles = selectedValues.map((key) => item.options.find((option) => option.key === key)?.title);
    onSubmit(selectedValues, selectedTitles);
  };
  const onSelect = (value: string, key: string, skip?: boolean) => {
    if (skip) {
      // Clear previous values and set only the skipped value
      const skipValues = { [value]: true };
      setValues(skipValues);
      // Get the title for the skipped value
      const skipTitles = [item.options.find((option) => option.key === value)?.title];
      onSubmit([value], skipTitles);
      return;
    }

    const newValues = { ...values };
    if (newValues[value]) {
      delete newValues[value];
    } else {
      newValues[value] = true;
    }
    setValues(newValues);
  };

  useEffect(() => {
    const isValid = Object.keys(values).length > 0;
    setValid(isValid);
  }, [values]);

  return (
    <div className="multiple-question-container">
      <QuestionImage item={item} />
      <QuestionText item={item} answers={answers} />
      {item.lottie && <QuestionLottie item={item} />}
      <OptionButtons item={item} onSubmit={onSelect} selected={values} additionalClass="multiple-question-options" />
      <div style={{ height: '10px' }} />
      <Button className="q-cta-button" disabled={!valid} onClick={submit}>
        {item.cta || 'Continue'}
      </Button>
    </div>
  );
};