export interface BrandConfig {
    gtmId: string;
    gaId: string;
    logo: string;
    defaultNextUrl: string;
    domains?: string[];
}

export const brandConfig: Record<string, BrandConfig> = {
    lulutox: {
        gtmId: 'GTM-52DSM4C',
        gaId: 'UA-XXXXXXXX-1111',
        logo: 'https://lulutox.com/common_file/logo.png',
        defaultNextUrl: 'https://lulutox.com/products/product-qzbf75',
        domains: ['quiz.lulutox.com']
    },
    wellaray: {
        gtmId: 'GTM-K4MVGF2Z',
        gaId: 'UA-XXXXXXXX-1111',
        logo: 'https://www.wellaray.com/index_files/logo.svg',
        defaultNextUrl: 'https://wellaray.com/products/product-1',
        domains: ['quiz.wellaray.com', 'quiz.getwellaray.com']
    },
    wellnee: {
        gtmId: 'GTM-xxxxx',
        gaId: 'UA-XXXXXXXX-1111',
        logo: 'https://trywellnee.com/index_files/logo.svg',
        defaultNextUrl: 'https://cart.trywellnee.com/products/product-1',
        domains: ['quiz.wellnee.com']
    },
};
