import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { IQuizItem } from '../../types/quiz';

type Props = {
  item: IQuizItem;
};

export const QuestionLottie = ({ item }: Props) => {
  const [animationData, setAnimationData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadAnimation = async () => {
      try {
        const response = await fetch(item.lottie?.path);
        const data = await response.json();
        setAnimationData(data);
      } catch (err) {
        console.error('Failed to load Lottie animation:', err);
        setError('Failed to load animation');
      }
    };

    if (item.lottie) {
      loadAnimation();
    }
  }, [item.lottie]);

  if (error) {
    return <div>Error loading animation</div>;
  }

  if (!animationData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`lottie-container ${item.lottie?.className}`} style={{ width: item.lottie?.width, height: item.lottie?.height }}>
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
      />
    </div>
  );
};