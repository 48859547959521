import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import QuizOld from './components/QuizOld';
import { Quiz } from './components/Quiz';
import Testing from './components/Testing';
import { QuizLulutoxTest } from './components/QuizLulutoxTest';
import { QuizLulutoxTestGender } from './components/QuizLulutoxTestGender';
import { QuizLulutoxTestUIX } from './components/QuizLulutoxTestUIX';
import { WellarayTikTok } from './components/WellarayTikTok';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Quiz} />
        {/* quiz.lulutox.com Routes */}
        <Route path="/v1" Component={QuizLulutoxTest} />
        <Route path="/tt" element={<QuizLulutoxTestGender questionsId="lu-tt" nextUrl="http://getlulutox.com/products/product-tt" />} />
        <Route path="/vbf" element={<QuizLulutoxTest nextUrl="https://shop.lulutox.com/products/product-1-s" appendAnswersToUrl={true} questionsId='lu-test-summary' />} />
        <Route path="/vbf-2" element={<QuizLulutoxTestUIX nextUrl="https://shop.lulutox.com/products/product-1-s" appendAnswersToUrl={true} questionsId='lu-test-summary-2' />} />
        <Route path="/vbf-sub" element={<QuizLulutoxTestUIX nextUrl="https://shop.lulutox.com/products/product-sub-s" appendAnswersToUrl={true} questionsId='lu-test-summary-2' />} />
        <Route path="/vbf-old" element={<QuizLulutoxTest nextUrl="https://lulutox.com/products/product-qzbf75" />} />

        {/* quiz.wellaray.com Routes */}
        <Route path="/vbf-w" element={<QuizLulutoxTest nextUrl="https://wellaray.com/products/product-1/" questionsId='wellaray-main' />} />
        <Route path="/vbf-w-tt" element={<WellarayTikTok nextUrl="https://wellaray.com/products/product-tt" questionsId='wellaray-tt' />} />
        <Route path="/vbf-w-sub" element={<QuizLulutoxTest nextUrl="https://wellaray.com/products/product-sub/" questionsId='wellaray-main' />} />

        {/* quiz.getellaray.com Routes */}
        <Route path="/vbf-gw-sub" element={<QuizLulutoxTest nextUrl="https://getwellaray.com/products/product-sub/" questionsId='wellaray-main' />} />
      </Routes>
    </Router>
  );
};

export default App;
