import { IQuizItem } from '../../types/quiz';

import { ChartQuestion } from './ChartQuestion';
import { EmailQuestion } from './EmailQuestion';
import { InfoQuestion } from './InfoQuestion';
import { Loader } from './Loader';
import { MultipleQuestion } from './MultipleQuestion';
import { SingleQuestion } from './SingleQuestion';
import { ReviewLoader } from './ReviewLoader';
import { GenderQuestion } from './GenderQuestion';
import { AgeQuestion } from './AgeQuestion';

type Props = {
  item: IQuizItem;
  onSubmit: (value?: string | string[], key?: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
  loaderType?: 'review' | 'default';
};

export const Questions = ({
  item,
  onSubmit,
  lastQuestion,
  answers,
  loaderType,
}: Props) => {
  const Question = {
    info: InfoQuestion,
    email: EmailQuestion,
    chart: ChartQuestion,
    single: SingleQuestion,
    multiple: MultipleQuestion,
    gender: GenderQuestion,
    age: AgeQuestion,
    loader: loaderType === 'review' ? ReviewLoader : Loader,
  }[item.type];

  return Question ? (
    <Question
      item={item}
      onSubmit={onSubmit}
      lastQuestion={lastQuestion}
      answers={answers}
    />
  ) : null;
};
