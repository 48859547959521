import { IQuizItem } from '../types/quiz';
import { Questions } from './questions/Questions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import '../styles/Quiz.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Gtm } from '../apis/gtm';
import { reportToMakeBq } from '../utils/bq';
import { getQuizById, Quiz as QuizType } from '../data/quizzes';
import { brandConfig } from '../config/brandConfig';
import { DefaultHeader } from './headers/DefaultHeader';
import { BannerHeader } from './headers/BannerHeader';
import { DefaultProgressBar } from './progressBars/DefaultProgressBar';
import { BannerProgressBar } from './progressBars/BannerProgressBar';
import DiscountLabel from '../assets/svg/DiscountLabel';
import { FeaturedLogoFooter } from './Footers/FeaturedLogoFooter';
import { CountdownBanner } from './CountdownBanner';
import { LogoOnlyHeader } from './headers/LogoOnlyHeader';

export const QuizLulutoxTestUIX = ({
  questionsId = 'lu-test',
  nextUrl = '/',
  appendAnswersToUrl = false,
}: {
  questionsId?: string;
  nextUrl?: string;
  appendAnswersToUrl?: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);
  const [answers, setAnswer] = useState({});
  const [progress, setProgress] = useState(0);
  const [direction, setDirection] = useState('forward');
  const [quiz, setQuiz] = useState<QuizType | null>(null);
  const quizStartTracked = useRef(false);
  const params = new URLSearchParams(location.search);
  const quizParam = questionsId;
  const successUrlParam = params.get('successUrl') || '/';

  const getBrandFromHostname = () => {
    const hostname = window.location.hostname;
    // Check each brand's domains
    for (const [brand, config] of Object.entries(brandConfig)) {
      console.log(brand, config.domains, hostname);
      if (config.domains?.some(domain => hostname.endsWith(domain))) {
        return brand;
      }
    }

    // Fallback to the old logic for backward compatibility
    const parts = hostname.split('.');
    if (parts.length >= 2) {
      const potentialBrand = parts[parts.length - 2];
      if (brandConfig[potentialBrand]) {
        return potentialBrand;
      }
    }
    return 'default';
  };

  const Header =
    {
      default: DefaultHeader,
      bannerHeader: BannerHeader,
      logoOnlyHeader: LogoOnlyHeader,
    }[quiz?.headerStyle] ?? DefaultHeader;

  const Footer =
    {
      default: null,
      logosFooter: FeaturedLogoFooter,
    }[quiz?.footerStyle] ?? null;

  const ProgressBar =
    {
      default: DefaultProgressBar,
      bannerHeader: BannerProgressBar,
    }[quiz?.progressBarStyle] ?? DefaultProgressBar;

  const brandParam = params.get('brand') || getBrandFromHostname();
  const brandLogo = brandConfig[brandParam]?.logo;

  useEffect(() => {
    const selectedQuiz = getQuizById(quizParam);
    if (selectedQuiz) {
      setQuiz(selectedQuiz);
    } else {
      console.error(`Quiz with id ${quizParam} not found`);
    }
  }, [quizParam]);

  useEffect(() => {
    if (!quizStartTracked.current) {
      Gtm.trackQuizStart(brandParam);

      reportToMakeBq({
        dataset: 'bigquery',
        table: 'quiz_events',
        data: {
          brand: brandParam,
          url: window.location.href,
          event: 'quiz_start',
          auid: params.get('utm_auid'),
        },
      });
      quizStartTracked.current = true;
    }
  }, [params, quiz, brandParam]);

  useEffect(() => {
    if (quiz) {
      setProgress(((idx + 1) / quiz.questions.length) * 100);
    }
  }, [idx, quiz]);

  const question = quiz?.questions[idx] as IQuizItem;

  const onAnswerSubmit = async (
    v?: string | string[],
    title?: string | string[]
  ) => {
    if (question.type === 'loader') {
      onFinish(answers);
      return;
    }

    setDirection('forward');
    let value = v;
    if (question.type === 'chart' || question.type === 'info') {
      value = 'clicked_next';
      title = 'clicked_next';
    }

    if (question.type === 'gender') {
      value = v;
      title = v;
    }

    console.log('value', value);
    console.log('title', title);

    const newAnswers = {
      ...answers,
      [question.key]: {
        value,
        title: title ?? value,
      },
    };

    console.log('newAnswers', newAnswers);

    setAnswer(newAnswers);

    const event = {
      brand: brandParam,
      step: idx,
      key: question.key,
      url: window.location.href,
      auid: params.get('utm_auid'),
      question: question.title ?? question.topTitle,
      answer: Array.isArray(title) ? title.join(', ') : title,
      answer_key: Array.isArray(value) ? value.join(', ') : value,
      questionType: question.type,
      quizId: quizParam,
    };

    Gtm.trackQuizAnswer(event);
    reportToMakeBq({
      dataset: 'bigquery',
      table: 'quiz_events',
      data: {
        ...event,
        event: 'quiz_answer',
      },
    });

    if (idx === quiz.questions.length - 1) {
      await onFinish(newAnswers);
    } else {
      setIdx(idx + 1);
    }
  };

  const handleBack = () => {
    if (idx === 0) {
      navigate(-1);
    } else {
      setDirection('backward');
      setIdx(idx - 1);
    }
  };

  const onFinish = async (
    answers: Record<string, { value: string | string[]; title: string }>
  ) => {
    const emailAnswer = answers['email'];
    const email =
      emailAnswer && typeof emailAnswer.value === 'string'
        ? emailAnswer.value
        : '';

    Gtm.trackQuizFinish(brandParam, email);

    await reportToMakeBq({
      dataset: 'bigquery',
      table: 'quiz_events',
      data: {
        step: idx,
        questionType: question.type,
        question: question.title,
        key: question.key,
        answer_key: email,
        brand: brandParam,
        answer: email,
        url: window.location.href,
        event: 'quiz_finish',
        auid: params.get('utm_auid'),
        allAnswers: answers,
        email: email,
        quizId: quizParam,
      },
    });

    handleRedirect();
  };

  const handleRedirect = () => {
    const defaultNextUrl = brandConfig[brandParam]?.defaultNextUrl;

    const constructUrlWithParams = (baseUrl: string) => {
      const url = new URL(baseUrl);
      const currentParams = new URLSearchParams(location.search);
      currentParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });

      if (appendAnswersToUrl) {
        const mainDomain = window.location.hostname.split('.').slice(-2).join('.');
        const cookieOptions = `path=/; domain=.${mainDomain}; max-age=${60 * 60 * 24 * 30}`;

        document.cookie = `quizAnswers=${JSON.stringify(answers)};${cookieOptions}`;
      }

      return url.toString();
    };

    let targetUrl: string;

    if (!nextUrl || nextUrl === '/') {
      targetUrl = constructUrlWithParams(defaultNextUrl);
    } else {
      targetUrl = constructUrlWithParams(nextUrl);
    }

    setTimeout(() => (window.location.href = targetUrl), 1000);
  };

  if (!quiz) {
    return <div>Loading quiz...</div>;
  }

  return (
    <>
      <div className={`quiz-container-test gender-test-2 ${brandParam}`}>
        <div className="quiz-background"></div>
        <div className="quiz-content">
          <Header
            handleBack={handleBack}
            brandLogo={brandLogo}
            idx={idx}
            quiz={quiz}
          />
          {question.type !== 'loader' && (
            <ProgressBar progress={progress} step={question.step} idx={idx} />
          )}
          <div className={`question-container question-${question.type}`}>
            <TransitionGroup>
              <CSSTransition
                key={idx}
                timeout={300}
                classNames={`question-${direction}`}
              >
                <div>
                  <div className="question-wrapper">
                    <Questions
                      answers={answers}
                      item={question}
                      onSubmit={onAnswerSubmit}
                      lastQuestion={idx === quiz.questions.length - 1}
                      loaderType={quiz.loaderType}
                    />
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
        {quiz.footerStyle && idx === 0 ? <Footer /> : null}
      </div>
    </>
  );
};
